export const EN = {
    LOADING: 'Loading',
    DASHBOARD: 'Dashboard',
    CATEGORIES: 'Categories',
    TAGS: 'Tags',
    TRENDS: 'Trends',
    BANNERS: 'Banners',
    WIDGETS: 'Widgets',
    PAGES: 'Pages',
    USERS: 'Users',
    DOCUMENTS: 'Documents',
    ARTICLE: 'Article',
    EPISODE: 'Episode',
    GALLERY: 'Gallery',
    INFOGRAPHIC: 'Infographic',
    AUTHORS: 'Authors',
    GROUPS: 'Groups',
    PERMISSIONS: 'Permissions',
    PROFILE: 'Profile',
    LOGOUT: 'Logout',
    NO_EVENTS: 'no events for you',
    WELCOME_MESSAGE: 'Welcome to admin panel',
    DASHBOARD_WELCOME: 'Welcome to admin panel',
    ARTICLE_LIST: 'Articles list',
    EPISODE_LIST: 'Episodes list',
    GALLERY_LIST: 'Gallery list',
    INFOGRAPHIC_LIST: 'Infographics list',
    LOADING_DOCUMENTS: 'Documents loading',
    EDIT: 'edit document',
    VIEW: 'preview document',
    DELETE: 'delete document',
    CROSSLINKS: 'crosslinks',
    SHOW_ALL_TAGS: 'show all tags',
    HIDE_ALL_TAGS: 'hide all tags',
    IMAGE: 'image',
    COVER: 'cover',
    VIDEO: 'video',
    EXTERNALVIDEO: 'external video',
    CODE: 'code',
    CATEGORY: "Category",
    AUTHOR: 'Author',
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    PREPUBLISHED: 'prepublished',
    CREATE: 'Create',
    TITLE_OR_URL: 'title or url',
    SELECT_STATUS: 'select status',
    SELECT_TAGS: 'select tags',
    SELECT_CATEGORY: 'select category',
    DATE_FROM: 'date from',
    DATE_TO: 'date till',
    ALL: 'all',
    EMPTY_DOCUMENTS: 'No documents',
    CLEAR_FILTER: 'clear_filter',
    CREATED: 'Created',
    UPDATED: 'Updated',
    AT: 'at',
    SURE_DELETE: 'You are sure that you want to delete this document?',
    CLOSE: 'Close',
    NOT_SET: '[not set]',
    ARTICLE_ADD: 'Add article',
    UPLOAD_TEXT: 'Click or drag file to this area to upload',
    COVER_LABEL: 'Cover',
    UPLOAD_NEW: 'New image',
    UPLOAD_SEL: 'Select image',
    /* workflow statuses */
    DRAFT: 'Draft',
    PUBLISH: 'Published',
    READY: 'Ready',
    ARCHIVE: 'Archive',
    PREPUBLISHED_DASHBOARD: 'Prepublished',
    SEARCH: 'Search',
    CLEAR: 'Clear',
    SEARCH_PLACEHOLDER: 'Your search query',
    SELECT_WORKFLOW: 'Workflow',
    BANNER_LIST: 'Banners',
    DOCUMENT_CREATED: 'Document has been created',
    TITLE: 'Title',
};

export default EN;
